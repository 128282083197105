// utils.ts
import { addLocale } from 'primereact/api';

export const encodeStudentName = (name: string): string => {
    // Simple reversible encoding function (Caesar cipher by shifting characters)
    const shift = 5;
    return Array.from(name).map(char => String.fromCharCode(char.charCodeAt(0) + shift)).join('');
};

export const decodeStudentName = (encoded: string): string => {
    const shift = 5;
    return Array.from(encoded).map(char => String.fromCharCode(char.charCodeAt(0) - shift)).join('');
};

export interface CourseWork {
  courseId: string;
  courseTitle: string;
  courseWorkId: string;
  courseWorkTitle: string;
  grade: string | null;
  gradeCategory: string | null;
  link: string;
  status: string;
  deadline: string;
  teacherName: string | null;
  optional: boolean;
}

const isAfterDeadline = (datetimeString: string | null): boolean => {
  if (!datetimeString) return false;
  
  const deadlineDate = new Date(datetimeString);
  const currentDate = new Date();

  // Check if the parsed date is valid
  if (isNaN(deadlineDate.getTime())) {
    return false;
  }

  // Compare current date with the deadline
  return currentDate > deadlineDate;
};

export const calculateSubmissionPercentage = (courseWorks: CourseWork[]): number => {
  // mandatory and passed bonus assignments
  const mandatoryCourseWorks = courseWorks.filter((courseWork) => courseWork.optional === false || (courseWork.optional === true && courseWork.grade !== null && parseInt(courseWork.grade) >= 2));
  const passedCourseWorks = mandatoryCourseWorks.filter((courseWork) => courseWork.grade !== null && parseInt(courseWork.grade) >= 2 && courseWork.status !== 'EXCUSED'); // 1 as that means it was submitted
  const notExcusedCourseWorks = mandatoryCourseWorks.filter((courseWork) => (courseWork.grade !== null || ((courseWork.status == "NOT_TURNED_IN" || courseWork.status == "FAILED") && isAfterDeadline(courseWork.deadline) )) && courseWork.status !== 'EXCUSED');
  const submissionPercentage = notExcusedCourseWorks.length > 0 ? (passedCourseWorks.length / notExcusedCourseWorks.length) * 100 : 100;
  return submissionPercentage;
};

export const calculateSubmissionPercentageInTime = (courseWorks: CourseWork[]): number => {
  const mandatoryCourseWorks = courseWorks.filter((courseWork) => courseWork.optional === false || (courseWork.optional === true && courseWork.grade !== null && parseInt(courseWork.grade) >= 2));
  const gradedCourseWorksInTime = mandatoryCourseWorks.filter((courseWork) => courseWork.grade !== null && parseInt(courseWork.grade) >= 2 && courseWork.status == "TURNED_IN");
  const notExcusedCourseWorks = mandatoryCourseWorks.filter((courseWork) => (courseWork.grade !== null || ((courseWork.status == "NOT_TURNED_IN" || courseWork.status == "FAILED") && isAfterDeadline(courseWork.deadline) )) && courseWork.status !== 'EXCUSED');
  const percentage = notExcusedCourseWorks.length > 0 ? (gradedCourseWorksInTime.length / notExcusedCourseWorks.length) * 100 : 100;
  return percentage;
};

export const calculateAverageGrade = (courseWorks: CourseWork[]): number => {
  const gradedQualitativeCourseWorks = courseWorks.filter(
    (courseWork) => courseWork.grade !== null && courseWork.status !== 'EXCUSED' && courseWork.gradeCategory == "Kvalitativní hodnocení"
  );

  const totalGrades = gradedQualitativeCourseWorks.reduce((sum, courseWork) => sum + (Number(courseWork.grade) || 0), 0);
  const average = gradedQualitativeCourseWorks.length > 0 ? totalGrades / gradedQualitativeCourseWorks.length : 4;

  return average;
};

export const getAverageGradeName = (courseWorks: CourseWork[]): string => {
  const gradedQualitativeCourseWorks = courseWorks.filter(
    (courseWork) => courseWork.grade !== null && courseWork.status !== 'EXCUSED' && courseWork.gradeCategory == "Kvalitativní hodnocení"
  );

  const totalGrades = gradedQualitativeCourseWorks.reduce((sum, courseWork) => sum + (Number(courseWork.grade) || 0), 0);
  if (gradedQualitativeCourseWorks.length == 0) {
    return "Chybí hodnocení kvalitativní práce"
  }
  const average = totalGrades / gradedQualitativeCourseWorks.length ;
  const grade = Math.round(average);

  switch (grade) {
    case 0:
      return 'Neodevzdáno';
    case 1:
      return 'Neovládám';
    case 2:
      return 'Ovládám s obtížemi';
    case 3:
      return 'Ovládám';
    case 4:
      return 'Ovládám s přehledem';
    default:
      return 'Ještě nehodnoceno';
  }
};

export const getAverageGradeNameTeacherCentric = (courseWorks: CourseWork[]): string => {
  const gradedQualitativeCourseWorks = courseWorks.filter(
    (courseWork) => courseWork.grade !== null && courseWork.status !== 'EXCUSED' && courseWork.gradeCategory == "Kvalitativní hodnocení"
  );

  const totalGrades = gradedQualitativeCourseWorks.reduce((sum, courseWork) => sum + (Number(courseWork.grade) || 0), 0);
  if (gradedQualitativeCourseWorks.length == 0) {
    return "Chybí"
  }
  const average = totalGrades / gradedQualitativeCourseWorks.length ;
  const grade = Math.round(average);

  switch (grade) {
    case 0:
      return 'Neodevzdáno';
    case 1:
      return 'Neovládá';
    case 2:
      return 'Ovládá s obtížemi';
    case 3:
      return 'Ovládá';
    case 4:
      return 'Ovládá s přehledem';
    default:
      return 'Ještě nehodnoceno';
  }
};

export const determineStatusClass = (percentage: number, averageGrade: number): string => {
  return percentage >= 90 && averageGrade > 1.5 ? 'excellent' : 'bad';
};




addLocale('cs', {
    "accept": "Ano",
    "addRule": "Přidat pravidlo",
    "am": "dopoledne",
    "apply": "Použít",
    "cancel": "Zrušit",
    "choose": "Vybrat",
    "chooseDate": "Vyberte datum",
    "chooseMonth": "Vyberte měsíc",
    "chooseYear": "Vyberte rok",
    "clear": "Vyčistit",
    "contains": "Obsahuje",
    "custom": "Vlastní",
    "dateAfter": "Datum je po",
    "dateBefore": "Datum je před",
    "dateFormat": "d. m. yy",
    "dateIs": "Datum je",
    "dateIsNot": "Datum není",
    "dayNames": [
      "Neděle",
      "Pondělí",
      "Úterý",
      "Středa",
      "Čtvrtek",
      "Pátek",
      "Sobota"
    ],
    "dayNamesMin": [
      "Ne",
      "Po",
      "Út",
      "St",
      "Čt",
      "Pá",
      "So"
    ],
    "dayNamesShort": [
      "Ned",
      "Pon",
      "Úte",
      "Stř",
      "Čtv",
      "Pát",
      "Sob"
    ],
    "emptyFilterMessage": "Nebyly nalezeny žádné výsledky",
    "emptyMessage": "Žádné dostupné možnosti",
    "endsWith": "Končí na",
    "equals": "Rovná se",
    "filter": "Filtr",
    "firstDayOfWeek": 1,
    "gt": "Větší než",
    "gte": "Větší než nebo rovno",
    "lt": "Menší než",
    "lte": "Menší než nebo rovno",
    "matchAll": "Odpovídá všem",
    "matchAny": "Odpovídá jakémukoli",
    "medium": "Střední",
    "monthNames": [
      "Leden",
      "Únor",
      "Březen",
      "Duben",
      "Květen",
      "Červen",
      "Červenec",
      "Srpen",
      "Září",
      "Říjen",
      "Listopad",
      "Prosinec"
    ],
    "monthNamesShort": [
      "Led",
      "Úno",
      "Bře",
      "Dub",
      "Kvě",
      "Čer",
      "Čvc",
      "Srp",
      "Zář",
      "Říj",
      "Lis",
      "Pro"
    ],
    "nextDecade": "Následující desetiletí",
    "nextHour": "Následující hodina",
    "nextMinute": "Následující minuta",
    "nextMonth": "Následující měsíc",
    "nextSecond": "Následující sekunda",
    "nextYear": "Následující rok",
    "noFilter": "Bez filtru",
    "notContains": "Neobsahuje",
    "notEquals": "Nerovná se",
    "now": "Teď",
    "passwordPrompt": "Zadejte heslo",
    "pending": "Čekající",
    "pm": "odpoledne",
    "prevDecade": "Předchozí desetiletí",
    "prevHour": "Předchozí hodina",
    "prevMinute": "Předchozí minuta",
    "prevMonth": "Předchozí měsíc",
    "prevSecond": "Předchozí sekunda",
    "prevYear": "Předchozí rok",
    "reject": "Ne",
    "removeRule": "Odstranit pravidlo",
    "startsWith": "Začíná na",
    "strong": "Silné",
    "today": "Dnes",
    "upload": "Nahrát",
    "weak": "Slabé",
    "weekHeader": "Týd.",
  });