import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaAngleRight  } from 'react-icons/fa6';
import './StudentView.css';
import StudentCourseCard from './StudentCourseCard';
import StudentCourseworkTable from './StudentCourseworkTable';
import { encodeStudentName, decodeStudentName, CourseWork, calculateAverageGrade, calculateSubmissionPercentage, calculateSubmissionPercentageInTime, determineStatusClass } from './utils';

interface CourseTableProps {
  data: CourseWork[];
}

const StudentView: React.FC<CourseTableProps> = ({ data }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [courses, setCourses] = useState<{ [key: string]: CourseWork[] }>({});
  const [studentContext, setStudentContext] = useState<string | null>(null);

  // Parse URL to get the current selected course ID and student context
  const searchParams = new URLSearchParams(location.search);
  const selectedCourseId = searchParams.get('courseId');
  const encodedStudentName = searchParams.get('student');

  useEffect(() => {
    if (encodedStudentName) {
      const decodedName = decodeStudentName(encodedStudentName);
      setStudentContext(decodedName); // Set student context if available in URL
    }
  }, [encodedStudentName]);

  // Aggregate data by courseId
  useEffect(() => {
    const aggregatedCourses = data.reduce<{ [key: string]: CourseWork[] }>((acc, curr) => {
      if (!acc[curr.courseId]) {
        acc[curr.courseId] = [];
      }
      acc[curr.courseId].push(curr);
      return acc;
    }, {});

    // Sort courses by deadline
    Object.keys(aggregatedCourses).forEach(courseId => {
      aggregatedCourses[courseId].sort((a, b) => new Date(b.deadline).getTime() - new Date(a.deadline).getTime());
    });

    setCourses(aggregatedCourses);
  }, [data]);

  // Sort course IDs alphabetically
  const sortedCourseIds = Object.keys(courses).sort((a, b) =>
    courses[a][0].courseTitle.localeCompare(courses[b][0].courseTitle)
  );

  const handleCardClick = (courseId: string) => {
    const newUrl = studentContext ? `?student=${encodeStudentName(studentContext)}&courseId=${courseId}` : `?courseId=${courseId}`;
    navigate(newUrl);
  };

  const handleBreadcrumbClick = () => {
    const newUrl = studentContext ? `?student=${encodeStudentName(studentContext)}` : '';
    navigate(newUrl);
  };

  // Ensure selected course exists before rendering
  const selectedCourse = selectedCourseId ? courses[selectedCourseId] : null;

  return (
    <div className="student-view-container">
      <div className="breadcrumb-container">
        <div
          className={`breadcrumb-item ${!selectedCourseId ? 'active' : ''}`}
          onClick={handleBreadcrumbClick}
        >
          Courses
        </div>
        {selectedCourseId && selectedCourse && (
          <>
            <span className="breadcrumb-separator">
              <FaAngleRight />
            </span>
            <div className="breadcrumb-item active">
              {selectedCourse[0].courseTitle}
            </div>
          </>
        )}
      </div>

      {selectedCourseId && selectedCourse ? (
        <StudentCourseworkTable
          courseTitle={selectedCourse[0].courseTitle}
          courseWorks={selectedCourse}
        />
      ) : (
        <div className="course-cards-container">
          {sortedCourseIds.map((courseId) => {
            const courseWorks = courses[courseId];
            const submissionPercentage = calculateSubmissionPercentage(courseWorks);
            const averageGrade = calculateAverageGrade(courseWorks);
            const statusClass = determineStatusClass(submissionPercentage, averageGrade);
            const inTimeSubmissionPercentage = calculateSubmissionPercentageInTime(courseWorks);

            return (
              <StudentCourseCard
                key={courseId}
                courseTitle={courseWorks[0].courseTitle}
                teacherName={courseWorks[0].teacherName!}
                submissionPercentage={submissionPercentage}
                inTimeSubmissionPercentage={inTimeSubmissionPercentage}
                statusClass={statusClass}
                onClick={() => handleCardClick(courseId)}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default StudentView;
